import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { AppVersion } from "components/common";
import { routes } from "config/routes";
import { useAuth } from "hooks/useAuth";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { RiArrowDownSLine, RiCloseFill } from "react-icons/ri";

export interface TopNavigationProps {
  title?: string;
  onBack?: () => void;
}

export const TopNavigation: React.FC<TopNavigationProps> = ({
  title,
  onBack,
}) => {
  const {
    auth: { user, admin },
    isAuthenticated,
    logout,
  } = useAuth();
  const router = useRouter();

  const fullName = useMemo(() => {
    return admin
      ? admin?.firstName + " " + admin?.lastName
      : user?.firstName + " " + user?.lastName;
  }, [admin, user]);

  return isAuthenticated ? (
    <Flex
      top={0}
      bg="white"
      pos="sticky"
      width="100%"
      height="60px"
      alignItems="center"
      px={["15px", "15px", "20px"]}
      borderBottom="1px solid"
      borderColor="gray.50"
      borderRadius="0 0 8px 8px"
      boxShadow="0px 1px 2px rgba(0, 0, 0, 0.05)"
      zIndex={1}
    >
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex gap="10px" alignItems="center">
          {!!onBack && (
            <Icon
              as={RiCloseFill}
              width="20px"
              height="20px"
              color="gray.600"
              onClick={onBack}
            />
          )}

          <Text
            fontFamily="archivo"
            fontWeight={onBack ? "medium" : "semibold"}
            fontSize={onBack ? "lg" : "xl"}
          >
            {title ?? "Obtainly"}
          </Text>
        </Flex>

        <Flex gap="10px" alignItems="center">
          <Menu>
            <MenuButton
              as={Button}
              variant="link"
              rightIcon={<RiArrowDownSLine />}
              px="0"
            >
              <Flex gap="6px" alignItems="center">
                <Avatar size="xs" name={fullName} />
                <Text
                  as="span"
                  color="body"
                  fontWeight="medium"
                  display={["none", "block"]}
                >
                  {fullName}
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              {!!admin ? (
                <MenuItem
                  onClick={() => router.push(routes.admin.manageAccount)}
                >
                  <Text fontWeight="medium">Manage Account</Text>
                </MenuItem>
              ) : (
                <MenuItem onClick={() => router.push(routes.user.onboarding)}>
                  <Text fontWeight="medium">Business Profile</Text>
                </MenuItem>
              )}
              <MenuItem
                onClick={() =>
                  router.push(routes[admin ? "admin" : "user"].settings)
                }
              >
                <Text fontWeight="medium">
                  {admin ? "System" : ""} Settings
                </Text>
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => logout()} _hover={{ bg: "critical.50" }}>
                <Text color="critical.600" cursor="default">
                  Logout
                </Text>
              </MenuItem>
              <MenuDivider />
              <MenuItem>
                <Flex justifyContent="center" w="100%">
                  <AppVersion />
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
};
