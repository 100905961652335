import { routes } from "config/routes";
import { useAuth } from "hooks/useAuth";
import { useRouter } from "next/router";
import { ElementType } from "react";
import {
  RiAdminFill,
  RiBankFill,
  RiBarChartBoxFill,
  RiBriefcase4Fill,
  RiContactsFill,
  RiDraftFill,
  RiHome6Fill,
  RiLineChartFill,
  RiProfileFill,
  RiSettings2Fill,
  RiUserFill,
} from "react-icons/ri";
import { DesktopNavigation } from "./DesktopNavigation";
import { MobileNavigation } from "./MobileNavigation";

export type NavItem = {
  link: string;
  icon: ElementType;
  label: string;
  showOn: ("desktop" | "mobile")[];
};

const navItems: { admin: NavItem[]; user: NavItem[] } = {
  admin: [
    {
      icon: RiHome6Fill,
      link: routes.admin.dashboard,
      label: "Home",
      showOn: ["mobile", "desktop"],
    },
    {
      icon: RiDraftFill,
      link: routes.admin.loanRequests,
      label: "Funding Requests",
      showOn: ["mobile", "desktop"],
    },
    {
      icon: RiBriefcase4Fill,
      link: routes.admin.vendors,
      label: "Vendors",
      showOn: ["mobile", "desktop"],
    },
    {
      icon: RiBriefcase4Fill,
      link: routes.admin.clients,
      label: "Clients",
      showOn: ["desktop"],
    },
    {
      icon: RiContactsFill,
      link: routes.admin.clientReferences,
      label: "Client Contacts",
      showOn: ["desktop"],
    },
    {
      icon: RiBankFill,
      link: routes.admin.creditProviders,
      label: "Credit Providers",
      showOn: ["desktop"],
    },
    {
      icon: RiUserFill,
      link: routes.admin.users,
      label: "Users",
      showOn: ["desktop"],
    },
    {
      icon: RiAdminFill,
      link: routes.admin.administrators,
      label: "Administrators",
      showOn: ["desktop"],
    },
    {
      icon: RiBarChartBoxFill,
      link: routes.admin.activitiesLog,
      label: "Activities Log",
      showOn: ["desktop"],
    },
    {
      icon: RiLineChartFill,
      link: routes.admin.rateSettings,
      label: "Rate Settings",
      showOn: ["desktop"],
    },
    {
      icon: RiBarChartBoxFill,
      link: routes.admin.reports,
      label: "Reports",
      showOn: ["desktop"],
    },
    {
      icon: RiSettings2Fill,
      link: routes.admin.settings,
      label: "System Settings",
      showOn: ["desktop"],
    },
    {
      icon: RiProfileFill,
      link: routes.admin.manageAccount,
      label: "Manage Account",
      showOn: ["desktop"],
    },
  ],
  user: [
    {
      icon: RiHome6Fill,
      link: routes.user.dashboard,
      label: "Home",
      showOn: ["mobile", "desktop"],
    },
    {
      icon: RiDraftFill,
      link: routes.user.loanRequests,
      label: "Funding Requests",
      showOn: ["mobile", "desktop"],
    },
    {
      icon: RiSettings2Fill,
      link: routes.user.settings,
      label: "Settings",
      showOn: ["mobile", "desktop"],
    },
  ],
};

export const Navigation = ({ type }: { type: "desktop" | "mobile" }) => {
  const {
    auth: { userType },
    isAuthenticated,
  } = useAuth();
  const router = useRouter();
  const isActive = (route: string) => {
    return router.pathname === route;
  };

  const navigationProps = {
    items: navItems?.[userType!] ?? [],
    isActive,
  };

  if (!isAuthenticated) {
    return null;
  }

  if (type === "mobile") {
    return <MobileNavigation {...navigationProps} />;
  }

  if (type === "desktop") {
    return <DesktopNavigation {...navigationProps} />;
  }

  return null;
};
