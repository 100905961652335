import { Box, BoxProps, Grid } from "@chakra-ui/react";
import { PasswordChangeModal } from "components/auth";
import { useAuth } from "hooks";
import dynamic from "next/dynamic";
import Head from "next/head";
import React, { PropsWithChildren } from "react";
import { BottomNavigation } from "./BottomNavigation";
import { Sidebar } from "./Sidebar";
import { TopNavigation, TopNavigationProps } from "./TopNavigation";

interface LayoutProps {
  title?: string;
  contentBox?: BoxProps;
  navigation?: TopNavigationProps;
}

const DynamicInstallPrompt = dynamic(
  () => import("components/common/InstallationWizard"),
  {
    ssr: false,
  }
);

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  title,
  children,
  contentBox,
  navigation,
}) => {
  const {
    auth: { user },
  } = useAuth();
  return (
    <>
      <Head>
        <title>{title ?? "Obtainly Dashboard"}</title>
      </Head>
      <DynamicInstallPrompt />
      <Grid
        padding={["0", "0", "10px"]}
        gridTemplateColumns={["auto", "auto", "300px auto"]}
      >
        <Sidebar />

        <Box
          bg={["transparent", "transparent", "white"]}
          height={["auto", "auto", "calc(100vh - 20px)"]}
          boxShadow={["", "", "0px 0px 4px rgba(38, 38, 38, 0.04)"]}
          borderRadius={["0px", "0px", "8px"]}
          overflow="auto"
        >
          <TopNavigation {...navigation} />
          <Box p={["15px", "15px", "20px"]} {...contentBox}>
            {children}
          </Box>
        </Box>

        <BottomNavigation />
      </Grid>

      {user?.promptPasswordChange && <PasswordChangeModal user={user} />}
    </>
  );
};
