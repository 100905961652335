import { Flex, Icon, List, ListItem, Text } from "@chakra-ui/react";
import Link from "next/link";
import { RiFolder5Fill } from "react-icons/ri";
import { NavItem } from "./Navigation";

export const DesktopNavigation = ({
  items,
  isActive,
}: {
  items: NavItem[];
  isActive: (route: string) => boolean;
}) => {
  const desktopItems = items.filter((item) => item.showOn.includes("desktop"));

  return (
    <List spacing="15px">
      {desktopItems.map((item) => {
        const active = isActive(item.link);
        return (
          <ListItem key={item.link} fontSize="15px">
            <Link href={item.link}>
              <a>
                <Flex gap="10px" alignItems="center">
                  <Icon
                    as={item.icon ?? RiFolder5Fill}
                    color={active ? "primary.500" : "gray.400"}
                    width="20px"
                    height="20px"
                  />

                  <Text color={active ? "primary.500" : "body"}>
                    {item.label}
                  </Text>
                </Flex>
              </a>
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
};
