import { Box, Flex } from "@chakra-ui/react";
import { useAuth } from "hooks/useAuth";
import { Navigation } from "./Navigation";

export const BottomNavigation = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <>
      <Flex
        pos="fixed"
        left={0}
        bottom={0}
        width="100%"
        bg="white"
        boxShadow="md"
        height="60px"
        alignItems="center"
        borderTop="1px solid"
        borderColor="gray.100"
        display={["flex", "flex", "none"]}
        px="15px"
        zIndex="sticky"
      >
        <Navigation type="mobile" />
      </Flex>
      <Box h="80px" display={["flex", "flex", "none"]} />
    </>
  ) : null;
};
