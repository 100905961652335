import { Box, Flex } from "@chakra-ui/react";
import { AppVersion, Logo } from "components/common";
import { useAuth } from "hooks/useAuth";
import React from "react";
import { Navigation } from "./Navigation";

export const Sidebar: React.FC = () => {
  const {
    auth: { user, admin },
    isAuthenticated,
  } = useAuth();

  return (
    <Flex
      flexDir="column"
      height="calc(100vh - 20px)"
      overflow="auto"
      justifyContent="space-between"
      display={["none", "none", "flex"]}
      pl="60px"
      pr="30px"
      pt="15px"
    >
      <Box>
        {isAuthenticated && <Logo />}

        <Box mt="50px">
          <Navigation type="desktop" />
        </Box>
      </Box>

      <Box mb="15px">
        <AppVersion />
      </Box>
    </Flex>
  );
};
