import { Box, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import Link from "next/link";
import { RiFolder5Fill } from "react-icons/ri";
import { NavItem } from "./Navigation";

export const MobileNavigation = ({
  items,
  isActive,
}: {
  items: NavItem[];
  isActive: (route: string) => boolean;
}) => {
  const mobileItems = items.filter((item) => item.showOn.includes("mobile"));

  return (
    <SimpleGrid
      columns={mobileItems.length}
      width="100%"
      spacing="15px"
      justifyContent="center"
      alignItems="center"
    >
      {mobileItems.map((item) => {
        const active = isActive(item.link);
        return (
          <Box key={item.link}>
            <Link href={item.link}>
              <a>
                <Flex
                  flexDir="column"
                  gap="4px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon
                    as={item.icon ?? RiFolder5Fill}
                    color={active ? "primary.500" : "gray.700"}
                    width="18px"
                    height="18px"
                  />
                  <Text
                    color={active ? "primary.500" : "gray.700"}
                    fontSize="sm"
                    fontWeight="medium"
                    textAlign="center"
                    noOfLines={1}
                  >
                    {item.label}
                  </Text>
                </Flex>
              </a>
            </Link>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};
